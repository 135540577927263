* {
  box-sizing: border-box;
}

.search-bar-container {
  position: relative;
  border: 1px solid $color-search-bar-border;
  padding: 1px 1px 0px 1px;
  border-radius: $border-radius-search-bar;
  cursor: text;
  min-height: 35px;
  max-height: 66px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex: 0;
  flex-wrap: wrap;
  background: $color-background;
  color: $color-text;
 
  .input-badge {
    position: relative;
    border-radius: $border-radius-search-bar;

    .search-bar-placeholder {
      position: absolute;
      height: 32px;
      font-size: 1.1em;
      line-height: 32px;
      left: 0.25em;
      top: 0;
      color: $color-search-bar-placeholder;
    }
  } 


  .search-bar-input {
    min-width: 10px;
    height: 25px;
    line-height: 25px;
    padding: 0 0.25em;
    border: none;
    font-size: 1.1em;

    &:focus {
      outline: none !important; //should remove the highlighting 
    }
  }
  
  .clear-all {
    height: 25px;
    width: 25px;
    background-color: $color-badge-interaction-bg;
    color: $color-text;
    border: 1px solid $color-badge-interaction-border;
    border-radius: $border-radius-search-bar;
    text-align: center;
    line-height: 25px;
    margin-bottom: 1px;
    margin-right: 5px;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1.1em;
    cursor: pointer;
  }
  
  .selected-option-badge {
    height: 25px;
    background-color: $color-badge-background;
    border: 1px solid $color-badge-border;
    border-radius: $border-radius-search-bar;
    margin-left: 1px;
    margin-bottom: 1px;
    overflow: hidden; //needed to allow rounded corners in the child-elements
    cursor: default;
    display: flex;
    
    .label {
      max-width: 150px;
      padding: 0 0.25em;
      font-size: 1.1em;
      font-weight: normal !important;
      line-height: 24px;
      vertical-align: middle;
      text-align: center;
      color: $color-text;
      text-overflow: ellipsis;
      white-space: nowrap; // No line breaks
      overflow: hidden; // require for text-overflow to work
    }
    
    .remove-x {
      width: 20px;
      text-align: center;
      line-height: 25px;
      font-size: 25px; // == height of the text
      vertical-align: middle;
      background-color: $color-badge-interaction-bg;
      font-weight: bold;
      padding: 0px 3px;
      cursor: pointer;
      border-top-left-radius: $border-radius-search-bar;
      border-bottom-left-radius: $border-radius-search-bar;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
    }
  }
}

;@import "sass-embedded-legacy-load-done:252";