// ------------
// colors
// ------------

$color-app: #0084c8; // favicon,  onboarding tooltips

// text
$color-text: #333333 !default;
$color-text-medium: #595959 !default;
$color-text-light: #737373 !default;

// background
$color-background: #FFF !default;
$color-app-background: #FFF !default;
$color-background-darker: #d4d4d4 !default;

// red for delete
$color-red: #DC3545 !default;

// shadow
$color-shadow: #d4d4d4 !default;

// borders
$color-border: #333333 !default;
$color-border-medium: #AAA !default;
$color-border-light: #d4d4d4 !default;

// cohort element
$color-cohort-background: #EFEFEF !default;
$color-bar-background: #FFF !default;
$color-bar: #595959 !default;
$color-remove-icon: #333333 !default;

// bar loading animation
$color-animation-bar-default: #D4D4D4 !default;
$color-animation-bar-highlight: #737373 !default;

// task element
$color-task-background: #EFEFEF !default;
$color-task-detail-icon: #333333 !default;

// path
$color-path-overview: #d4d4d4 !default;

// interaction
// --old hover color
// $color-hover: #52C0CC !default; //previous: #0097A7 #00B8CC , #29BCCC
// $color-hover-opacity-75: #52C0CCC0 !default;
// --new hover color
$color-hover: $color-app !default;
$color-hover-opacity-75: #0084c8C0 !default;
$color-selected: #FFC340 !default;
$color-selected-light: #FFCB59 !default;

// buttons
// -- Coral button
// normal
$color-btn-coral-bg: $color-background  !default; // bootstarp hover styling
$color-btn-coral-border: $color-border-light  !default; // bootstarp hover styling
// hover or selected
$color-btn-coral-first-bg: darken($color-btn-coral-bg,10%) !default;; // bootstarp hover styling
$color-btn-coral-first-border: darken($color-btn-coral-border,12%) !default;; // bootstarp hover styling
$box-shadow-btn-coral-selected: inset 0 3px 5px rgba(0, 0, 0, 0.13) !default;
// hover of selected
$color-btn-coral-second-bg: darken($color-btn-coral-first-bg,7%) !default;; // bootstarp hover styling for selected button
$color-btn-coral-second-border: darken($color-btn-coral-first-border,13%) !default;; // bootstarp hover styling for selected button

// -- Coral prime button
// normal
$color-btn-coral-prime: $color-background !default; // bootstarp hover styling
$color-btn-coral-prime-bg: $color-hover !default; // bootstarp hover styling
$color-btn-coral-prime-border: $color-hover !default; // bootstarp hover styling
// hover or selected
$color-btn-coral-prime-first-bg: darken($color-btn-coral-prime-bg,10%) !default;; // bootstarp hover styling
$color-btn-coral-prime-first-border: darken($color-btn-coral-prime-border,12%) !default;; // bootstarp hover styling
$box-shadow-btn-coral-prime-selected: inset 0 3px 5px rgba(0, 0, 0, 0.13) !default;
// hover of selected
$color-btn-coral-prime-second-bg: darken($color-btn-coral-prime-first-bg,7%) !default;; // bootstarp hover styling for selected button
$color-btn-coral-prime-second-border: darken($color-btn-coral-prime-first-border,13%) !default;; // bootstarp hover styling for selected button

// search bar
$color-search-bar-border: $color-app !default;
$border-radius-search-bar: 3px !default;
$color-search-bar-placeholder: $color-app !default;
// search bar badges
$color-badge-background: $color-app-background !default;
$color-badge-border: #AAAAAA !default;
$color-badge-interaction-bg: $color-app-background !default;
$color-badge-interaction-border: #AAAAAA !default;
// search bar option list
$color-search-bar-header-border: #000000 !default;
$color-search-bar-background: #EFEFEF !default;
// $color-searchbar-hover: $color-hover;
// $color-searchbar-selected: $color-selected;
$color-searchbar-hover: $color-badge-interaction-bg !default;
$color-searchbar-selected: $color-badge-background !default;
$box-shadow-searchbar-option-selected: inset 0 3px 5px rgba(0, 0, 0, 0.13) !default;
// search bar detail option list
$color-search-bar-option-detail-bg: #ffffff !default;
$color-search-bar-option-detail-header-bg: #ffffff !default;
$color-search-bar-option-detail-top-border: $color-border-light !default;
$color-search-bar-option-detail-bottom-border: #000000 !default;

// prevalence checkbox
$color-prevalence-cb-background: #EFEFEF !default;

// task view column header option button
$color-tv-opt: #AAA  !default;
$color-tv-opt-hover: $color-hover !default;
$color-tv-opt-active: #333 !default;



//darkgrey is #A9A9A9, see https://www.w3schools.com/colors/colors_shades.asp

// $unused: #C8DCDE !default;
// $unused2: #B5AA99 !default;

;@import "sass-embedded-legacy-load-done:87";