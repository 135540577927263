* {
  box-sizing: border-box;
}

.option-container-wrapper {
  position: absolute;
  z-index: 9001; //over 9000
}

.option-container {
  height: 326px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 55% 45%;
  border: 1px solid $color-border-light;
  border-top: none;
  border-radius: $border-radius-search-bar;

  .option-list {
    overflow: auto;
    background-color: $color-background;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    border-top-left-radius: $border-radius-search-bar;
    border-bottom-left-radius: $border-radius-search-bar;

    .option-group-header {
      padding: 5px 2px 5px 10px;
      border-top: 1px solid $color-search-bar-header-border;
      border-bottom: 1px solid $color-search-bar-header-border;
      font-weight: bolder;
    }

    .option {
      padding: 5px 2px 5px 15px;
      cursor: pointer;
      background-color: $color-background;
      border-bottom: 1px solid $color-border-light;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;

      .option-text-ensemble {
        margin-left: 5px;
        font-weight: normal;
        color: $color-text-light;
      }

      &:hover {
        background-color: $color-searchbar-hover;
        font-style: italic;
      }

      &.option-selected {
        background-color: $color-searchbar-selected !important;
        box-shadow: $box-shadow-searchbar-option-selected;

        &:hover {
          background-color: $color-searchbar-hover !important;
          font-style: italic;
        }
      }

      &.option-focus {
        // keyboard focus
        background: linear-gradient(
          90deg,
          $color-bar 0%,
          $color-bar 2%,
          $color-background 2%,
          $color-background 100%
        ) !important;
        font-style: italic;

        &.option-selected {
          box-shadow: $box-shadow-searchbar-option-selected;
          background: linear-gradient(
            90deg,
            $color-bar 0%,
            $color-bar 2%,
            $color-searchbar-selected 2%,
            $color-searchbar-selected 100%
          ) !important;

          &:hover {
            background: linear-gradient(
              90deg,
              $color-bar 0%,
              $color-bar 2%,
              $color-searchbar-hover 2%,
              $color-searchbar-hover 100%
            ) !important;
          }
        }

        &:not(.option-selected):hover {
          background: linear-gradient(
            90deg,
            $color-bar 0%,
            $color-bar 2%,
            $color-searchbar-hover 2%,
            $color-searchbar-hover 100%
          ) !important;
        }
      }
    }
  }

  .option-detail {
    background-color: $color-search-bar-option-detail-bg;
    border-left: 1px solid $color-border-light;
    height: 100%;
    grid-column: 2;
    grid-row: 1;
    overflow-y: auto;
    border-top-right-radius: $border-radius-search-bar;
    border-bottom-right-radius: $border-radius-search-bar;

    .detail-info {
      &.detail-dbc,
      &.detail-panel {
        overflow-x: hidden;
        p {
          word-wrap: break-word;
          padding-left: 2px;
          span {
            font-weight: bold;
          }
        }
      }

      &.detail-special {
        overflow: auto;
        background: $color-search-bar-option-detail-header-bg;
        height: 100%;

        .detail-info-label {
          padding: 5px 2px 5px 10px;
          background-color: $color-search-bar-option-detail-header-bg;
          font-weight: bolder;
        }

        .detail-info-text {
          padding: 5px 2px 5px 10px;
          background-color: $color-background;
          border-bottom: 1px solid $color-border-light;
        }

        .detail-info-group-header {
          padding: 5px 2px 2px 10px;
          border-top: 1px solid $color-search-bar-option-detail-top-border;
          border-bottom: 1px solid $color-search-bar-option-detail-bottom-border;
          font-weight: bolder;
        }

        .detail-info-option {
          padding: 2px 2px 2px 15px;
          cursor: pointer;
          background-color: $color-background;
          border-bottom: 1px solid $color-border-light;

          &:last-child {
            border-bottom: unset;
          }

          &:hover {
            background-color: $color-searchbar-hover;
            font-style: italic;
          }

          &.option-selected {
            background-color: $color-searchbar-selected !important;
            box-shadow: $box-shadow-searchbar-option-selected;

            &:hover {
              background-color: $color-searchbar-hover !important;
              font-style: italic;
            }
          }

          &.option-focus {
            // keyboard focus
            background: linear-gradient(
              90deg,
              $color-bar 0%,
              $color-bar 2%,
              $color-background 2%,
              $color-background 100%
            ) !important;
            font-style: italic;

            &.option-selected {
              box-shadow: $box-shadow-searchbar-option-selected;
              background: linear-gradient(
                90deg,
                $color-bar 0%,
                $color-bar 2%,
                $color-searchbar-selected 2%,
                $color-searchbar-selected 100%
              ) !important;

              &:hover {
                background: linear-gradient(
                  90deg,
                  $color-bar 0%,
                  $color-bar 2%,
                  $color-searchbar-hover 2%,
                  $color-searchbar-hover 100%
                ) !important;
              }
            }

            &:not(.option-selected):hover {
              background: linear-gradient(
                90deg,
                $color-bar 0%,
                $color-bar 2%,
                $color-searchbar-hover 2%,
                $color-searchbar-hover 100%
              ) !important;
            }
          }
        }
      }

      &.detail-gene {
        overflow: auto;
        background: $color-search-bar-option-detail-header-bg;
        height: 100%;

        .detail-info-gene-label {
          padding: 5px 2px 5px 10px;
          background-color: $color-search-bar-option-detail-header-bg;
          font-weight: bolder;

          .detail-info-gene-ensemble {
            margin-left: 5px;
            font-weight: normal;
            color: $color-text-light;
          }
        }
        .detail-info-group-header {
          padding: 5px 2px 2px 10px;
          border-top: 1px solid $color-search-bar-option-detail-top-border;
          border-bottom: 1px solid $color-search-bar-option-detail-bottom-border;
          font-weight: bolder;
        }

        .detail-info-option {
          padding: 2px 2px 2px 15px;
          cursor: pointer;
          background-color: $color-background;
          border-bottom: 1px solid $color-border-light;

          &:last-child {
            border-bottom: unset;
          }

          &:hover {
            background-color: $color-searchbar-hover;
            font-style: italic;
          }

          &.option-selected {
            background-color: $color-searchbar-selected !important;
            box-shadow: $box-shadow-searchbar-option-selected;

            &:hover {
              background-color: $color-searchbar-hover !important;
              font-style: italic;
            }
          }

          &.option-focus {
            // keyboard focus
            background: linear-gradient(
              90deg,
              $color-bar 0%,
              $color-bar 2%,
              $color-background 2%,
              $color-background 100%
            ) !important;
            font-style: italic;

            &.option-selected {
              box-shadow: $box-shadow-searchbar-option-selected;
              background: linear-gradient(
                90deg,
                $color-bar 0%,
                $color-bar 2%,
                $color-searchbar-selected 2%,
                $color-searchbar-selected 100%
              ) !important;
            
              &:hover {
                background: linear-gradient(
                  90deg,
                  $color-bar 0%,
                  $color-bar 2%,
                  $color-searchbar-hover 2%,
                  $color-searchbar-hover 100%
                ) !important;
              }
            }
          

            &:not(.option-selected):hover {
              background: linear-gradient(
                90deg,
                $color-bar 0%,
                $color-bar 2%,
                $color-searchbar-hover 2%,
                $color-searchbar-hover 100%
              ) !important;
            }
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:265";