
.header {
  display: flex;
  flex-direction: column;
  height: 42px; // = same height as (searchbar + padding) from the task column
 
  font-weight: 500;
  
  position: sticky;
  top: 0;
  
  background: inherit;
  z-index: 2; // because the mini vis were on top of the header

  border-bottom: 1px solid $color-border-light;
  padding: 2px 2px 0 2px; // more padding on top so the text is not so close to the border

  .header-title {
    font-size: 1.1em;
    height: 18px;
    line-height: 18px;
    margin-top: 3px;
    &.text {
      text-overflow: ellipsis;
      white-space: nowrap; // No line breaks
      overflow: hidden; // require for text-overflow to work
      text-align: center;
    }
  }

  .header-option {
    display: flex;
    flex-direction: row-reverse;
    height: 18px;
    line-height: 18px;
    

    .options {
      height: 18px;
      width: 12px;
      margin-left: 3px;
      font-size: 13px;
      color: $color-tv-opt;
      text-align: center;

      &.left-side {
        margin-right: auto;
      }
      
      &:hover {
        color: $color-tv-opt-hover;
        cursor: pointer;
      }

      &.active {
        color: $color-tv-opt-active;

        &:hover {
          color: $color-tv-opt-hover;
        }
      }

      &.remove{
        font-size: 12px !important;
        color: $color-tv-opt-active;
        order: 100;

        &:hover {
        color: $color-red;
        cursor: pointer;
        }
      }
    }

    .options.options-with-text {
      width: auto;
      display: flex;

      .options-text {
        font-size: 0.9em;
        padding: 0 2px;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:260";