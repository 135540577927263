
@mixin idtype_color($idType, $color) {
  form.search {
    .form-check-label[data-idtype="#{$idType}"] {
      color: $color;
    }
  }

  .select2-container--bootstrap  {
    .select2-selection--multiple .select2-selection__choice[data-idtype="#{$idType}"] {
      border-color: $color;
    }

    .select2-results__group[data-idtype="#{$idType}"] {
      background: $color;
      color: black;
    }

    .select2-results__option[data-idtype="#{$idType}"]:not([aria-selected=true]):not(.select2-results__option--highlighted) {
      background: tint-color($color, 22%);
      color: black;
    }
  }

  article.view[data-idtype="#{$idType}"] {
    border-color: $color;
  }

  .view-chooser {
    li[data-idtype="#{$idType}"] {
      border: 1px solid $color !important;

      &:active,
      &:hover {
        box-shadow: 0 8px 17px 0 rgba($color, 0.2), 0 6px 20px 0 rgba($color, 0.2) !important;
      }
    }
  }

  .nav-tabs > li[data-idtype="#{$idType}"] {
    > a:hover,
    &.active > a,
    &.active > a:hover,
    &.active > a:focus {
      border-top-color: $color;
      border-left-color: $color;
      border-right-color: $color;
    }
  }
}

;@import "sass-embedded-legacy-load-done:96";