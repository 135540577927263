.lineup-container {
  height: 100%;

  .lu-side-panel {
    // 20em is the value from the LineUpJs scss
    width: 20rem; // otherwise tdp_core/../_view_lineup.scss sets it to 100% 
    &.lu-collapsed{
      width: 0;
    }

    .lu-side-panel-chooser {
      display: none;
    }

    .lu-stats > span {
      border-color: $color-hover;
    }
  }

  .lineup-engine > main > footer {
    // these footers provide space so that the scrollbars look like you can browse over the whole table while actually lineup is always showing just a fraction
    display: block; // otherwise clue (selector = body[data-clue='E'] footer) sets it to 'none'
    width: 0; // it would be 50px causing the table body being 50px wider than the table header and the two scrolling out of sync
  }

  .le-tr.lu-selected, .le-tr:hover, .le-tr.le-highlighted {
    box-shadow: 0 0 0 2px $color-hover;
  }
}
;@import "sass-embedded-legacy-load-done:256";