.select3 {
  position: relative;

  select {
    width: 100%;
  }

  .select2-selection {
    border-radius: 0;
    min-height: 35px;

    @supports (-moz-appearance:meterbar) { // firefox only
      min-height: 36px;
    }
  }

  .select2-container.drag-over {
    position: relative;

    &::before {
      position: absolute;
      left: 2px;
      top: 2px;
      right: 2px;
      bottom: 2px;
      color: #b3b3b3;
      font-size: 150%;
      z-index: 1;
      content: 'Drop File Here...';
      line-height: 1.42857143;
      padding: 0 12px;
      background: white;
      pointer-events: none;
    }
  }

  .select2-selection__choice {
    &[data-verified=invalid] {
      text-decoration: line-through;

      .select2-selection__choice__remove {
        text-decoration: none;
        color: red;
      }
    }

    &[data-verified=processing] {
      opacity: 0.5;

      &::after {
        @extend .fas;
        content: fa-content($fa-var-spinner);
        animation: fa-spin 2s infinite linear;
      }
    }
  }

  &.select3-searching .select2-selection__rendered {
    position: relative;

    &::after {
      @extend .fas;
      content: fa-content($fa-var-spinner);
      position: absolute;
      right: 0;
      margin: 8px 6px 0 0;
      animation: fa-spin 2s infinite linear;
    }
  }
}

;@import "sass-embedded-legacy-load-done:137";