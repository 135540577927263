@keyframes login-shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

#loginDialog {
  &.has-error .modal-content {
    animation-duration: 1.0s;
    animation-name: login-shake;
  }

  .modal-body > div.alert {
    display: none;
  }

  &.has-warning .modal-body > div.alert-warning {
    display: block;
  }

  &.has-error .modal-body > div.alert-danger {
    display: block;
  }
}

;@import "sass-embedded-legacy-load-done:223";