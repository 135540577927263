#chtOverviewGraphWrapper {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.overview-element-highlight {
  // background-color: $color-hover !important;
  // color: $color-text !important;
  border-color: $color-hover !important;
  box-shadow: inset 0 0px 2px $color-hover-opacity-75 , 0 0 8px $color-hover-opacity-75 !important; 
}

.task-hist-element-highlight {
  // box-shadow: inset 0 0px 2px  rgba(0, 0, 0, 0.075), 0 0 8px $color-hover-opacity-75 !important; 
  box-shadow: inset 0 0px 2px 2px  $color-hover-opacity-75 !important; 
}

#chtOverviewGraph {
  position: relative;
  margin-top: 0.5em;
  margin-left: 0.5em;

  .preview {
    opacity: 0.5 !important;
  }

  .layout_rect {
    top: 0;
    left: 0;
    display: grid;
    grid-row-gap: 2px;
    box-sizing: border-box;

    @import './task/taskRectangle'; //rectange style of the tasks
  }
}

#svg-path-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  .svg-path {
    stroke: $color-path-overview;
    fill: none;
    opacity: 1;

    &.overview-path-highlight {
      stroke: $color-hover;
    }
  }  
}

#taskSideBar {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: auto;
 
  * {
    box-sizing: border-box;
  }

  .taskHistory {
    min-width: 51px;
    // width: 75px;
  }

  .taskDetails {
    width: 150px;
    display: flex;
    box-sizing: border-box;
    min-width: 0px !important;
  }

  .taskDetails.hide {
    visibility: hidden;
    width: 0px;
  }

  .task-sidebar-column {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $color-border;
    border-top: 1px solid $color-border;
    text-align: center;

    .task-history-header {
      min-height: 25px;
      max-height: 25px;
    }

    // remove scrollbar
    .task-history-stack-wrapper::-webkit-scrollbar { 
      display: none;  // Safari and Chrome
    }
    
    .task-history-stack-wrapper {
      overflow: auto;
      display: flex;
      flex-direction: column;
      flex: 1;

      // remove scrollbar
      -ms-overflow-style: none;  // IE 10+
      scrollbar-width: none;  // Firefox

      .task-history-stack {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        height: 100%;
        min-height: 100%;
        flex-direction: column;
        align-items: left; // alignment in row

        @import './task/taskRectangle'; //rectange style of the tasks 

        > div:not(.task-details) {
          box-sizing: border-box;
          width: 51px;
          height: 51px;
        }
        .task-details {
          width: 149px;
          height: 51px;
          flex-grow: 0;
          flex-shrink: 0;
          box-sizing: border-box;
          border-top: 1px solid $color-border;
          background-color: $color-task-background;
          overflow-x: auto;

          .task-detail-attribute {
            min-height: 15px;
            margin-top: 2px;
            font-size: 0.9em;
            line-height: 15px;
            padding-left: 2px;
            text-align: left;
            font-weight: normal;
            word-break: break-word;
            
            span {
              font-weight: bold;
            }
          }

          .task-detail-cohort {
            min-height: 15px;
            display: flex;
            flex-wrap: wrap;

            .task-detail-label {
              height: 15px;
              font-size: 0.9em;
              line-height: 15px;
              padding-left: 2px;
              padding-right: 5px;
              text-align: left;
              font-weight: bold;
            }

            .task-detail-icon {
              height: 8px;
              margin: 3px 2px 4px 2px;
              width: 12px;
              background-color: $color-task-detail-icon;

              &:hover {
                background-color: $color-hover;
              }
            }
          }
          
          // p {
          //   white-space: normal;
          //   font-size: x-small;
          //   padding-left: 2px;
          //   margin: 0;
          //   text-align: left;

          //   span {
          //     font-weight: bold;
          //   }
          // }
        }

        .rectTask { // not set without important
          border: unset !important;
          border-top: 1px solid $color-border !important;
        }
      }

      .task-history-stack > :first-child {
        margin-top: auto !important;
        /* justify-content: flex-end; --> BREAKS scrolling behaviour*/
    }

    }
    
  }

  #btnDetailsToggle, 
  #btnDetailsToggle:link, 
  #btnDetailsToggle:visited, 
  #btnDetailsToggle:active  {
    color: $color-text;
  }

  #btnDetailsToggle:hover  {
    color: $color-hover;
    text-decoration: none;
  }

  #btnDetailsToggle {

  
    &::before{
      font-family: 'Font Awesome 6 Free';
      content: '\f191';
      font-size: 20px;
    }

    &.close-detail::before{
      font-family: 'Font Awesome 6 Free';
      content: '\f152';
      font-size: 20px;
    }
  }

}
;@import "sass-embedded-legacy-load-done:262";