
header {
  opacity: 1;

  &[draggable] {
    cursor: pointer;
    cursor: -moz-grab;
    cursor: -webkit-grab; // indicate grab able
  }

  &.phovea-dragging {
    opacity: 0.2;
  }

  > button {
    &.btn-close,
    &.size-toggle {
      margin: 0 3px;
      cursor: pointer;
    }

    &.size-toggle {
      padding: 0;
      background: transparent;
      border: 0;
      opacity: .2;
      float: right;
    }
  }
}

;@import "sass-embedded-legacy-load-done:215";