.cohort_app {
  position: absolute; // absolute position this one should help in setting min/max sizes
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;

  // height of the initial taskview in vh, is later used to define the standard layout with pixels
  // the size in pixels is needed to change the split screen with the dragger
  --tv-height-vh: 50vh;

  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr 7px var(--tv-height); //50vh for detail view because otherwise i couldn't get scrolling to work inside the input/output cohort areas.
}

.control-bar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  padding: 0.25rem 0.5em;

  .control-bar-label {
    font-size: 1.2em;
    margin-right: 0.5em;
  }
}

#modalWindow {
  left: 25%;
  top: 10%;
  width: 50%; /* Full width */
  height: 50%; /* Full height */
}

// define default Coral button style
.btn-coral {
  background-color: $color-btn-coral-bg;
  border: 1px solid $color-btn-coral-border;
  border-radius: 3px;
  //box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.40);

  &.disabled {
    background-color: $color-btn-coral-bg; 
    color: $color-btn-coral-border; //same as border
  }

  &:not(.disabled) {
    &:hover, &:focus {
      background-color: $color-btn-coral-first-bg; // bootstarp hover styling
      border-color: $color-btn-coral-first-border; // bootstarp hover styling
      //border-color: $color-hover;
      //box-shadow: 0 0 9px $color-hover !important;
    }

    &.selected, &.active {
      box-shadow: $box-shadow-btn-coral-selected; // bootstrap selection styling
      background-color: $color-btn-coral-first-bg; // bootstarp selection styling
      border-color: $color-btn-coral-first-border; // bootstarp selection styling

      &:hover, &:focus {
        background-color: $color-btn-coral-second-bg; // bootstarp hover styling for selected button
        border-color: $color-btn-coral-second-border; // bootstarp hover styling for selected button
      }
    }
  }
}

// define primary action Coral button style
.btn-coral-prime {
  background-color: $color-btn-coral-prime-bg;
  color: $color-btn-coral-prime;
  border: 1px solid $color-btn-coral-prime-border;
  border-radius: 3px;
  //box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.40);

  &.disabled {
    color: $color-btn-coral-prime-border; //same as border
    background-color: $color-btn-coral-prime; //text color
  }

  &:not(.disabled) {
    &:hover, &:focus {
      // background-color: $color-hover;
      // border-color: $color-hover;
      // box-shadow: 0 0 9px $color-hover !important;
      color: $color-btn-coral-prime;
      background-color: $color-btn-coral-prime-first-bg; // bootstarp hover styling
      border-color: $color-btn-coral-prime-first-border; // bootstarp hover styling
    }

    &.selected, &.active {
      color: $color-btn-coral-prime;
      box-shadow: $box-shadow-btn-coral-prime-selected; // bootstrap selection styling
      background-color: $color-btn-coral-prime-first-bg; // bootstarp selection styling
      border-color: $color-btn-coral-prime-first-border; // bootstarp selection styling

      &:hover, &:focus {
        color: $color-btn-coral-prime;
        background-color: $color-btn-coral-prime-second-bg; // bootstarp hover styling for selected button
        border-color: $color-btn-coral-prime-second-border; // bootstarp hover styling for selected button
      }
    }
  }
}

.screen-controls {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 50px;
  padding: 0 2px;
  box-sizing: border-box;

  .sc-button {
    height: 20px;
    width: 20px;
    margin: 0 2px;
    display: flex;
    // border: 1px solid $color-border-light;
    // border-radius: 2px;

    .icon {
      width: 20px;
      border: 1px solid $color-bar;
      margin: 3px;
      background-color: white;

      div {
        border-bottom: 1px solid $color-bar;
        background-color: white;
      }
    }
  }
}

#chtOverview {
  display: flex;
  overflow: auto;

  @import './overview'; //overview styling

}


#dragger {
  background: $color-cohort-background;
  border-top: 1px solid  $color-border-light;
  border-bottom: 1px solid  $color-border-light;

  &:hover {
    background: $color-background-darker !important;
    cursor: row-resize;
  }

  svg.dragger-dots {
    margin: auto;
    display: block;
    height: 5px;
    width: 18px;

    circle, rect {
      fill: $color-bar;
    }
  }

}

;@import "sass-embedded-legacy-load-done:267";