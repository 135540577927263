@import './vars';

&[data-layout=root] {
  flex: 1 1 auto;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  position: relative;

  > header {
    flex: 0 0 auto;
    background-color: $phovea_layout_view_header;
  }
  > main {
    flex: 1 1 auto;
    position: relative;
  }

  > section.maximized-view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    > header {
      background-color: $phovea_layout_view_header;
    }

    > article {
      flex: 1 1 auto;
    }
  }
}

;@import "sass-embedded-legacy-load-done:216";