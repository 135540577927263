

.tooltip-serachbar {
  width: 500px;

  .tooltip-txt {
    width: auto;
  }

  @import "./searchBar/searchbar";

  .tooltip-controls{
    display: flex;
    direction: rtl;
    padding: 5px 0px 0px 0px;

    .tooltip-btn{
      // cursor: pointer;
      flex: 0 1;
      // height: 20px;
      // line-height: 20px;
      // border-radius: 4px;
      margin-left: 5px;
      // padding: 0 5px;
    }
  }
}
;@import "sass-embedded-legacy-load-done:264";