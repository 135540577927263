
.data {
  

  &.deselected {
    opacity: 0.5;
  }

  
  .hist {
    width: 100%;
    height: 100%;
    padding: 3px 1px;
    > div {
      width: 100%;
      height: 100%;
    }

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
    }

    &.text{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px; //Cohort Representation + Padding = 52px + 2*2px= 56px


      > div { 
        width: unset;
        height: unset;
        max-width: 100%;
        max-height: 40px;
        line-height: 20px;
        text-overflow: ellipsis;
        text-align: center;
        // mutli line text trimming: https://css-tricks.com/line-clampin/  (license: https://css-tricks.com/license/)
        display: -webkit-box;   
        -webkit-line-clamp: 2;   
        -webkit-box-orient: vertical;     
        overflow: hidden; 
      }
    }
  }

  &.first-output-cohort {
    margin-top: 10px;
  }

  &.last-output-cohort {
    padding-bottom: 10px;
    border-bottom: 1px solid $color-border-light;
  }
}

;@import "sass-embedded-legacy-load-done:261";