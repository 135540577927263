.tdp-session-dialog {
  [data-menu="temporary"] > h4::before {
    @extend .fas;
    content: fa-content($fa-var-clock);
  }
  & + .modal-footer {
    display: none;
  }
}

;@import "sass-embedded-legacy-load-done:200";