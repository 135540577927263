@import './vars';

&[data-layout=lineup] {
  display: flex;
  flex-wrap: nowrap;

  &[data-mode=stacked] {
    > section {
      flex-basis: auto;
    }
  }

  &[data-orientation=h] {
    overflow-x: auto;
    flex-direction: row;
  }

  &[data-orientation=v] {
    overflow-y: auto;
    flex-direction: column;
  }

  > section {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;

    > header {
      flex: 0 0 auto;
      background-color: $phovea_layout_view_header;
    }

    > main,
    > article {
      flex: 1 1 auto;
      position: relative;
    }
  }

}

;@import "sass-embedded-legacy-load-done:220";