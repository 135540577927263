.text-overlay {
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  opacity: 0.98;

  font-size: larger;

  width: 100%;
  height: 100%;

  z-index: 99;

  div.editable:hover {
    border: 1px solid black;
  }

  div.text-overlay-body textarea {
    min-height: 5em;
  }
}

main.hide-all-non-annotations {
  > * {
    //visibility: hidden !important;
    opacity: 0.1 !important;
  }
  .annotation, .subtitle-annotation, .annotation-anchor {
    //visibility: visible !important;
    opacity: 1 !important;
  }
}

.text-overlay, .text-annotation {
  > div {
    position: relative;

    textarea {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

div.add-text-annotation {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}

div.annotation {
  position: absolute;
  left: 0;
  top: 0;

  font-size: larger;

  z-index: 100;

  > div {
    width: 100%;
    height: 100%;
  }

  button {
    position: absolute;
    padding: 3px 6px;
    &.fa-arrows {
      top: -1.2em;
      left: -1.2em;
      cursor: move;
    }
    &.fa-times {
      top: -1.2em;
      left: 1.0em;
    }
    &.fa-rotate-right {
      bottom: -1.2em;
      left: -1.2em;
    }
    &.fa-expand {
      bottom: -1.2em;
      right: -1.2em;
    }
  }
}

.subtitle-annotation {
  left: 41vw;
  bottom: 0;
  text-align: center;
  position: absolute;
}

.text-annotation {
  &.editable:hover line {
    border: 1px solid $annotation_color_hover;
  }
}

.arrow-annotation {
  line {
    stroke: $annotation_color;
    stroke-width: 5;
    stroke-linecap: round;
  }

  marker {
    fill: $annotation_color;
  }

  circle.anchor {
    fill: black;
    cursor: move;
    opacity: 0.5;
    &:hover {
      opacity: 0.8;
    }
  }

  &.editable:hover line {
    stroke: $annotation_color_hover;
  }

  svg {
    position: absolute;
  }
}

.frame-annotation {
  border-radius: 5px;
  border: 5px solid $annotation_color;

  &.editable:hover {
    border: 5px solid $annotation_color_hover;
  }
}
.annotation-anchor {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: $anchor_color_hover;
  &.closest {
    background-color: $anchor_color;
  }
}

;@import "sass-embedded-legacy-load-done:210";