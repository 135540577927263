.provenance-story-vis {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;

  width: 22em;

  &:first-child {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  > div:last-of-type {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    text-align: center;
    div#player_controls {
      margin-left: auto;
      margin-right: auto;
    }
  }

  h2 > i:last-of-type {
    float: right;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }

  div.line {
    position: absolute;
    left: 39px;
    border-left: 1px solid gray;
    top: 0;
    width: 1px;
    bottom: 0;
  }

  div.time_marker {
    position: absolute;
    z-index: 2;
    left: 36px;
    font-size: 50%;
    color: red;
  }

  div.current {
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    height: 20vh;

    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;

    border-bottom: 2px solid $core_border;
    margin-bottom: 2px;

    input, textarea, div {
      display: block;
    }
    textarea, div.description {
      -webkit-flex: 1 1 auto;
      flex: 1 1 auto;
    }
  }

  div.stories {
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow-x: auto;
  }

  &.vertical {
    div.stories {

    }
  }
  &.horizontal {
    > div:first-of-type {
      min-width: 7.5em;
      vertical-align: top;
      display: inline-block;

      div.toolbar > div {
        display: block;
      }
    }
    div.stories {
      display: inline-block;
    }
  }

  div.toolbar {
    height: 22px;
  }

  .dropdown {
    padding: 0;

    > a {
      color: inherit;
    }
    ul {
      font-size: 85%;
    }
    a {
      text-decoration: none;
      padding: 2px;
    }
  }


  &.medium {

    div.toolbar {
      display: none;
    }
  }

  &.small {
    div.toolbar {
      display: none;
    }
  }
}

div.stories {

  &.vertical {

    div.story {
      height: 5em;
      margin-left: 43px;
      -webkit-flex-direction: row;
      flex-direction: row;

      div.dragger {
        position: absolute;
        bottom: -2px;
        height: 5px;
        left: 0;
        right: 0;

        &:hover:not(.hover) {
          cursor: ns-resize;
        }
      }
      div.dragger-transition {
        position: absolute;
        top: -2px;
        height: 5px;
        left: 0;
        right: 0;

        &:hover:not(.hover) {
          cursor: ns-resize;
        }
      }

      &.placeholder:not(:last-of-type) {
        height: 5px;


      }

      &.placeholder:last-of-type {
        height: auto;
        padding: 0.5em 1em;
      }

      div.preview {
        max-width: 4em;
        width: 100%;

        background-position: center center;
        background-repeat: repeat-y;
      }
    }

    &.large {
      width: 100%;
    }

    &.medium {
      width: 4em;
    }

    &.small {
      width: 1em;
    }
  }
  &.horizontal {

    div.story {
      width: 5em;
      margin: 2px;

      -webkit-flex-direction: column;
      flex-direction: column;

      &.placeholder:not(:last-of-type) {
        width: 5px;

        &:hover:not(.hover):not(:first-of-type) {
          cursor: ew-resize;
        }

        &:last-of-type {
          width: 3em;
        }
      }

      &.placeholder:last-of-type {
        border-width: 1px;
        width: auto;
        padding: 1em 0.5em;
      }

      div.preview {
        height: 4em;

        background-position: top center;
        background-repeat: repeat-x;
      }
    }

    &.large {
      height: 10em;
    }

    &.medium {
      height: 4em;
    }

    &.small {
      height: 1em;
    }
  }

  //transition: all 200ms linear;

  div.story {
    position: relative;
    display: -webkit-flex;
    display: flex;
    margin: 0 2px;
    margin-bottom: 3px;

    border-radius: 2px;

    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;

    &.placeholder, div.dragger, div.dragger-transition {
      //border: 2px solid #efefef;
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;

      &.hover {
        background-color: #959595;
        border-color: #959595
      }
    }
    &.placeholder {

      background-color: white;

      > div {
        margin-left: auto;
        margin-right: auto;
      }

      &:last-of-type {
        border-radius: 5px;
        border-width: 1px;
        border-color: #b6b6b6;
        border-style: dashed;

        background: repeating-linear-gradient(
            45deg,
            #dbdbdb,
            #efefef 10px,
            #d7d7d7 20px
        );

        &.hover {
          background: repeating-linear-gradient(
              45deg,
              #959595,
              #efefef 10px,
              #959595 15px
          );
        }
      }
    }

    &:not(.placeholder) {
      //border: 1px solid gray;
      background-color: #f0f0f0;
      border-radius: 5px;

      &.phovea-select-selected {
        border-color: $select-color;
        background-color: $select-color-bright;
        div.duration {
          color: $select-color;
          font-weight: bold;
        }
      }
      &.phovea-select-hovered {
        border-color: $hover-color;
        div.duration {
          color: $hover-color;
          font-weight: bold;
        }
      }
    }

    div.preview {
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;

      background-color: white;
      background-clip: border-box;
      background-size: contain;

      border-radius: 5px 0 0 5px;

      margin-right: 2px;
    }

    div.slabel {
      display: block;
      position: relative;

      -webkit-flex: 1 1 auto;
      flex: 1 1 auto;

      overflow: hidden;
      text-overflow: ellipsis;
    }

    div.toolbar {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      display: none;
      letter-spacing: 2px;
      /*i {
        margin: 0 0.1em;
      }*/

      i:hover {
        font-weight: bold;
      }
    }

    div.duration {
      position: absolute;
      top: -5px;
      left: -36px;
      font-size: 75%;
      color: #949494;
      text-align: right;

      i.fas {
        padding-left: 2px;
        font-size: 75%;
      }
    }
  }

  &.large {
    div.story {


      &:hover {
        div.toolbar {
          display: block;
        }
      }
    }
  }

  &.large.no-thumbnails {
    div.story div.preview {
      display: none;
    }

    div.slabel {
      margin-left: 2px;
    }
  }

  &.medium {
    div.story {
      div.slabel {
        display: none;
      }
      &.placeholder, div.dragger, div.dragger-transition {
        display: none;
      }
    }
  }

  &.small {
    div.story {
      div.preview, div.slabel, &.placeholder, div.duration, div.dragger, div.dragger-transition {
        display: none;
      }

      &.phovea-select-selected {
        background-color: $select-color;
      }
      &.phovea-select-hovered {
        background-color: $hover-color;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:212";