body, html {
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

th, label, strong, b, .label {
  font-weight: 500 !important;
}

#headerAboutDialog .modal-dialog {
  width: 50vw;
  min-width: 600px;
  max-width: 900px;
}

#deleteModal .modal-header-flex {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  align-items: flex-start;
}

.hint {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-color: $color-border;
}

.app-color {
  color: $color-app;
}

@import './cohortRectangle'; //rectange style of the cohorts

;@import "sass-embedded-legacy-load-done:246";