$lu_dialog_font_size: 0.9rem !default;
$lu_toolbar_font_size: 0.9rem !default;

$lu_assets: '~lineupjs/src/assets' !default;
$lu_use_font_awesome: true !default;
// do not include Font Awesome files from LineUp, but use ours instead
$lu_include_font_awesome: false !default;

@import '~lineupjs/src/style.scss';

.lu {
  // use a fixed font-size for the ranking + side panel with the Roboto font family.
  // this font-size corresponds with the visual size of the previous font family Yantramanav
  // and allows to display more rows by default.
  // the LineUp zoom buttons are still working as they are using percentages (e.g., `font-size: 100%`)
  // which works relative to the font size defined here.
  font-size: 13.4px;
  // unset to reset the LineUp default line height and set it to the Bootstrap default line height
  line-height: unset;
}

// override the font-size manually (since the SCSS variables above does not work)
.lu-tooltip,
.lu-dialog {
  // adapt LineUp dialogs to Bootstrap v4 dialog style
  padding: 1rem 1rem 0.5rem;
  gap: 10px;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;

  strong {
    margin: unset;
  }
}

.lu-dialog {
  input {
    font-size: inherit;
  }

  .lu-checkbox:not(.lu-dialog-filter-table-entry) > span {
    font-size: inherit;
    margin: unset;
  }

  strong:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.lu-dialog-buttons {
  margin-top: 0.5rem;
}

.lu-dialog-button {
  margin: unset;
}

.lu-summary {
  font-size: inherit;

  .lu-checkbox > span {
    font-size: inherit;
    margin: unset;
  }
}

.lu-stats {
  font-size: inherit;

  & > span {
    font-size: inherit;
  }
}

.lu-side-panel-summary {
  font-size: inherit;
  margin: 0.5rem;

  &.lu-renderer-string {
    gap: 10px;
  }
}

.lu-side-panel-ranking,
.lu-group-hierarchy,
.lu-sort-hierarchy,
.lu-sort-groups-hierarchy {
  margin-bottom: 1rem;

  &::before {
    font-size: inherit;
  }
}

.lu-side-panel-entry {
  padding: 0 0 1rem;
}

.lu-side-panel-entry-header {
  font-size: inherit;
  border-top: unset;
  padding: 0.5rem;
  transition: background-color 0.3s ease;
}

.lu-side-panel-labels {
  padding: 0;
}

.lu-dialog {
  .lu-checkbox {
    display: flex;
    align-items: baseline;
    gap: 5px;
    margin: unset;
  }
}

.lu-dialog.lu-more-options {
  padding: 0.5rem 0;
  gap: 0;

  & > i.lu-action {
    align-items: baseline;
    padding: 0.25rem 1.5rem 0.25rem 0.75rem;
    gap: 0.5rem;

    & > span {
      margin: unset;
    }
  }
}

.lu-search::before {
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #ced4da;
}

.lu-hierarchy .lu-hierarchy-adder .lu-search::before {
  height: unset;
}

.lu-search-input {
  height: unset;
  border-color: #ced4da;
}

.lu-search-list {
  top: 1.85rem;

  li.lu-search-item > span,
  li.lu-search-group > span {
    padding: 0.5rem;
  }
}

.lu-search-open > input {
  border-radius: 0 4px 0 0;
}

.lu-hierarchy-entry,
.lu-hierarchy-adder {
  padding: 0.3rem 0.5rem;
  align-items: baseline;
  gap: 5px;
  border-top: none;
}

.lu-hierarchy-adder > .lu-search > input {
  height: 1.8rem;
  font-size: inherit;
}

span.lu-mapping-hint {
  font-size: small;
}

.lu-histogram-min-hint,
.lu-histogram-max-hint {
  font-size: inherit;
}

.lu-histogram-max,
.lu-histogram-min {
  &::before {
    bottom: -1.2rem;
    font-size: small;
  }
}

.lu-histogram .lu-checkbox {
  bottom: -3rem;
}

.lu-dialog-rename {
  width: 20rem;
}

.lu-dialog-mapper-warning {
  @extend .alert; // apply base styles of Bootstrap alert
  @extend .alert-warning;
  margin-bottom: 0; // override margin
}

;@import "sass-embedded-legacy-load-done:138";