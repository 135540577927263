@import './vars';

&[data-layout=split] {
  display: flex;
  align-items: stretch;

  &[data-orientation=h] {
    flex-direction: row;

    > [data-layout=separator]:not(.fixed) {
      cursor: ew-resize;
      flex-direction: column;

      span {
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;

        &:first-child {
          //left
          border-left: none;
          margin-bottom: 10px;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  &[data-orientation=v] {
    flex-direction: column;

    > [data-layout=separator]:not(.fixed) {
      cursor: ns-resize;
      flex-direction: row;

      span {
        border-left-color: transparent !important;
        border-right-color: transparent !important;

        &:first-child {
          //left
          border-top: none;
          margin-right: 10px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  > section {
    flex: 1 1 0;
    display: inline-flex;
    flex-direction: column;
    position: relative;

    > header {
      flex: 0 0 auto;
      background-color: $phovea_layout_view_header;
    }

    > main,
    > article {
      flex: 1 1 auto;
      position: relative;
    }
  }

  &.slider-dragging {
    -moz-user-select: none;
    user-select: none;

    > section {
      pointer-events: none;
      opacity: 0.5;

      @supports (-moz-appearance:meterbar) { // firefox only
        * {
          display: none;
        }
      }
    }
  }

  > [data-layout=separator] {
    flex: 0 0 5px;
    background: $phovea_layout_separator_color;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(.fixed) {

      &:hover {
        background: shade-color($phovea_layout_separator_color, 20%);

        span {
          border-color: tint-color($phovea_layout_separator_hints, 20%);
        }
      }
    }

    span {
      display: block;
      width: 0;
      height: 0;
      border: 5px solid $phovea_layout_separator_hints;

      cursor: pointer;

      &:hover {
        border-color: white;
      }
    }

    &.fixed {
      flex: 0 0 2px;
      span {
        display: none;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:219";