@use "sass:math";

.#{$tdp-css-prefix}-view {
  &.multiple {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: row;
    gap: 4rem;
  }

  &.proxy_view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    p {
      flex: 0 0 auto;
      margin: 0.5em;
    }

    iframe {
      margin: -1px; // to fix some corner case regarding positioning
      border: none;
      width: 100%;
      height: 100%;
    }
  }

  &:not(.tdp-busy).tdp-hint, &:not(.tdp-busy).tdp-hint-mapping {
    &::before {
      @extend .far;
      content: fa-content($fa-var-eye-slash);
      $nothing: 50pt !default;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: math.div(-$nothing, 2);
      margin-top: math.div(-$nothing, 2);
      font-size: $nothing;
      z-index: 9;
    }

    &[data-hint]::after {
      position: absolute;
      top: 1em;
      left: 1em;
      right: 1em;
      text-align: center;
      content: attr(data-hint);
      z-index: 10;
    }

    > * {
      display: none !important;
    }
  }

  &:not(.tdp-busy).tdp-hint-mapping::before {
    content: $fa-var-unlink;
  }

  @import './view_lineup';
  @import './view_table';
  @import './view_composite';
  @import './view_proxy';
}

;@import "sass-embedded-legacy-load-done:204";