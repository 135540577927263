$control-height: 35px !default;
$gap: 0.25em !default;
$hover-animation-time: 0.4s !default;

.task-view-search {
  height: 100%;
  padding-top: 0.5em;

  border-left: 1px solid $color-border-light;
  border-right: 1px solid $color-border-light;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  .header {
    display: flex;
    flex-direction: row;
    // gap: $gap; // handled by margin of search bar below because chrome doesnt support "gap" yet
    margin: 0 $gap;

    .task-title {
      font-size: 1.1em;
      padding: 0.1em 0.5em;
      margin: 0 0 0 $gap;
      height: 35px;
      line-height: 35px;
      display: flex;
      align-items: center;
    }

    .search-bar {
      flex-shrink: 1; // can shrink
      flex-grow: 1; // can grow
      margin: 0 $gap;

      @import "./searchBar/searchbar";
    }

    .action {
      width: $control-height;
      height: $control-height;
      font-size: 1.8em;
      flex-shrink: 0; // can not shrink
      flex-grow: 0; // can not grow

      //center:
      display: flex;
      justify-content: center;
      align-items: center;

      border: 1px solid $color-border-light;
      border-radius: $control-height/7;

      &.disabled {
        color: $color-border-light; //same as border
      }

      &:not(.disabled) {
        box-shadow: -1px 1px 1px 0px $color-shadow; // button pops out of page

        &:hover {
          box-shadow: -1px 1px 2px 1px $color-shadow; // button pops even more out of page
        }
        &:active {
          //on click
          box-shadow: inset -1px 1px 5px 0px $color-shadow; // button is pushed into page
        }
      }

      &.selected {
        box-shadow: inset -1px 1px 3px 0px $color-shadow; // button is pushed into page, a bit less shadow on click
        color: $color-selected;

        &:hover {
          box-shadow: inset -2px 2px 5px 0px $color-shadow; // button is pushed into deeper in page
        }
        &:active {
          box-shadow: inset -3px 3px 5px 0px $color-shadow; // button is pushed even into deeper in page
        }
      }
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1em;
    overflow-y: auto;

    @import "./task/taskchooser";
    @import "./task/tasks";
  }
}

;@import "sass-embedded-legacy-load-done:251";