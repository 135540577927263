$task-width: 8em !default;
$task-gap: 1em !default;

.task-selector {
  z-index: 2;
  transform-origin: top left;

  &.minimize {
    transform: scale(0.1);
    transition: transform 0.2s linear;
  }


  .tasks-header, .task-list-header {
    font-size: 1.75rem;

    &.task-list-header {
      margin: 0.5em 0;
      border-bottom: 1px solid $color-border-light;

      .info {
        font-size: 0.5em;
      }
    }
  }

  p {
    font-size: 1rem;
  }

  .tasks {
    padding: 0 1em;
  }

  .task-list {
    display: grid; // grid instead of flexbox because chrome can handle gap for grids
    grid-auto-flow: column; // fill columns first
    justify-content: start; // don't stretch the task buttons
    gap: $task-gap;
  }

  .task {
    width: $task-width;
    height: 6em;
    // border: 1px solid $color-border-light;
    // border-radius: 0.25em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    cursor: pointer;

    &.dual {
      // grid-column-end: span 2;
      width: $task-width*2+$task-gap;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;

      > div {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;

        &:first-child {
          border-right: 1px dashed $color-border-light;
        }
      }

      &:hover {
        > div {
          border-color: darken($color-border-light,12%);  // bootstarp hover styling
        }
      }
    }

    // &.disabled {
    //   color: $color-border-light; //same as border
    // }

    // &:not(.disabled) {
    //   box-shadow: -1px 1px 3px 0px $color-shadow; // button pops out of page

    //   &:hover {
    //     box-shadow: -1px 1px 5px 1px $color-shadow; // button pops even more out of page
    //     text-shadow: -1px -1px $color-shadow;
    //   }
    //   &:active {
    //     //on click
    //     box-shadow: inset -1px 1px 5px 0px $color-shadow; // button is pushed into page;
    //     text-shadow: 1px 1px $color-shadow;
    //   }
    // }

    .task-icon, .task-label {
      display: flex;
      justify-content: center;
      align-items: center;

      &.task-label {
        padding-bottom: 0.25em;
        font-size: 1rem;
      }
      &.task-icon {
        font-size: 2.5rem;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:253";