&.tdp-table {
  thead th {
    position: sticky;
    top: -1px;
    background: white;
    vertical-align: top;

    &[data-sort] {
      cursor: pointer;

      &::before {
        float: right;
        @extend .fas;
        content: fa-content($fa-var-sort);
      }
    }

    &[data-export] {
      &::before {
        margin-right: 1.5em;
      }

      a {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
      }
    }

    &[data-sort=asc]::before {
      content: fa-content($fa-var-sort-alpha-down);
    }

    &[data-sort=asc][data-num]::before {
      content: fa-content($fa-var-sort-numeric-down);
    }

    &[data-sort=desc]::before {
      content: fa-content($fa-var-sort-alpha-up);
    }

    &[data-sort=desc][data-num]::before {
      content: fa-content($fa-var-sort-numeric-up);
    }
  }
}

;@import "sass-embedded-legacy-load-done:206";