@import './vars';

article[data-layout=view] {
  display: flex;

  > * {
    flex: 1 1 auto;
  }
}

;@import "sass-embedded-legacy-load-done:221";