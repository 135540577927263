.workflow-carousel {
    .carousel.carousel-fade {
      opacity: 1;
    }
    .carousel.carousel-fade .carousel-item {
        transition: opacity ease-out .7s;
        left: 0;
        opacity: 0; /* hide all slides */
        top: 0;
        position: absolute;
        width: 100%;
        display: block;
    }
    .carousel.carousel-fade .carousel-item:first-child {
        top: auto;
        position: relative;
    }
    .carousel.carousel-fade .carousel-item.active {
        opacity: 1;
    }
    
    .carousel-inner {
      .carousel-item {
        padding: 5px; //padding to see images box shadow (see below)
        position: relative;

        img, object, svg {
          position: relative;
          top: 0;
          width: 80%; //not 100% to move the next/previous controls out of the img
          margin: auto; // center image
          
          box-shadow: 3px 3px 6px 3px $color-shadow; // image pops out of page

          &:not(:first-child) {
            position: absolute;
            box-shadow: unset; // first image's shadow is stil there

            // keep parents padding
            top: 5px;
            left: calc(10% + 5px);
            right: calc(10% + 5px);
            width: unset; // specified from left & right offset

            //animation
            opacity:0;
            animation: 1s forwards fadein;
            animation-delay: 2s;
            animation-play-state: paused; // dont start animation until slide is active
          }
        }

        &.active {
          img, object, svg {
            &:not(:first-child) {
              animation-play-state: running;
            }
          }
        }

        &.videoWrapper {
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
        
          iframe {
            position: absolute;
            top: 0;
            left: 10%;
            width: 80%;
            height: 80%;
          }
        }
      }

      .carousel-caption {
        // bootstrap's default color is white
        color: $color-text;
        text-shadow: unset;

        // // moveσ caption below image
        //   // position: relative;
        //   // left: auto;
        //   // right: auto;
      }
    }

    .carousel-indicators {
      bottom: -2.5em;
      
      li{
        // Image indicators would be white and rectangular shaped by default
        border: 1px solid #2f353a;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        
        &.active {
          background-color: #2f353a;
        }
      }
    }

    .carousel-control-prev {
      left: $carousel-control-width * -0.2;
    }

    .carousel-control-next {
      right: $carousel-control-width * -0.2;
    }
  }

  @keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
;@import "sass-embedded-legacy-load-done:273";