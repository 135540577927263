@keyframes tdp-tour-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

$step-color: black !default;
$step-border: lightgray !default;


.tdp-tour-backdrop-blocker {
  display: none;
  position: absolute;
  z-index: 1999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: tdp-tour-fade-in 0.2;
}
.tdp-tour-backdrop {
  display: none;
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  animation: tdp-tour-fade-in 0.2s;
  transition: clipPath ease 1s;
}

.tdp-tour-step-count {
  display: none;
  position: absolute;
  background: $step-color;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  left: -0.8rem;
  top: -0.8rem;
  z-index: 2002;
  justify-content: center;
  align-items: center;
  font-size: small;
  text-align: center;
  color: white;
  box-shadow: rgba($step-color, 0.35) 0px 5px 15px;
  transition: transform ease 1s;
  animation: tdp-tour-fade-in 0.4s;
}

.tdp-tour-step {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: rgba($step-color, 0.35) 0px 5px 15px;
  background-color: white;
  border-radius: 5px;
  z-index: 2001;
  animation: tdp-tour-fade-in 0.4s;
  transition: transform ease 1s;
  flex-direction: column;
}

.tdp-tour-step-content {
  margin: 1rem;
  overflow: auto;
}


.tdp-tour-step-navigation {
  border-top: 1px solid $step-border;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;
  gap: 1rem;
}


.tdp-tour-step-dots {
  margin: 0.2em;
  align-self: center;
  display: flex;
  font-size: smaller;
  color: lightgray;

  > div {
    padding: 0.1em;
    cursor: pointer;
  }
}


.tdp-tour-step.tdp-tour-back-disabled {
  .tdp-tour-step-dots > div {
    pointer-events: none;
  }

  button[data-switch="-"] {
    display: none;
  }
}

;@import "sass-embedded-legacy-load-done:202";