.task-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 0.5em;

  .task-vis-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    height: 100%;


    .task-header {
      display: flex;
      // gap: 1em; // handled by margin of task title below because chrome doesnt support "gap" yet
      margin-bottom: 0.5em;
      grid-row: 1;
      grid-column: 1;

    }

    .task-body {
      grid-row: 2;
      grid-column: 1;
    }

    @import "./filter";
    @import "./details";
    @import "./prevalence";
    @import "./compare";
  }

}

;@import "sass-embedded-legacy-load-done:254";