/**
 * Additional jQuery select2 styles
 */
 @import "~select2/dist/css/select2.min.css";
 @import "~select2-bootstrap-theme/dist/select2-bootstrap.min.css";

.select2-results__option .ensg,
.select2-selection__rendered .ensg {
  font-size: 12px;
  color: #ccc;
}

.select2-container--bootstrap .select2-results > .select2-results__options {
  max-height: 300px;
}

.select2-container--bootstrap .select2-results > .select2-results__options[id^="select2-tdplineupcolumnadd"] {
  // different height for the column chooser
  max-height: 65vh;
}

.select2-selection__clear {
  margin-bottom: 1px;
}

.select2-results {
  font-size: 14px;
}

.select2-rendered__match {
  background: #FFEB3B;
  color: black;
}

.select2-results__options--nested .select2-results__option {
  padding-left: 25px !important;
}

.select2-container--bootstrap .select2-results__group {
  color: #444;
  background: #efefef;
}

.select2-dropdown {
  z-index: 2051;
}

// fix select2 placeholder cut-off from https://github.com/select2/select2/pull/4898#issuecomment-408568031
.select2-container .select2-selection__rendered > *:first-child.select2-search--inline {
  width: 100% !important;

  .select2-search__field {
    width: 100% !important;
  }
}

// support readonly
select[readonly].select2-hidden-accessible + .select2-container {
  pointer-events: none;
  touch-action: none;
}

select[readonly].select2-hidden-accessible + .select2-container .select2-selection {
  background: #eee;
  box-shadow: none;
}

select[readonly].select2-hidden-accessible + .select2-container .select2-selection__arrow, select[readonly].select2-hidden-accessible + .select2-container .select2-selection__clear {
  display: none;
}
;@import "sass-embedded-legacy-load-done:136";