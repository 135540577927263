.#{$tdp-css-prefix}-button-group {
  margin: 0;
  padding: 5px 5px;
  list-style: none;

  &.history {
    overflow: hidden;
  }

  //border-bottom: 1px solid $core_colour_selected;

  display: flex;

  li {
    margin-left: -1px;
    background: #fff;
    border: 1px solid $core_border;

    a {
      display: block;
      height: 100%;
      padding: 5px 10px;
      cursor: pointer;
      z-index: 1;
      color: #000;
      text-decoration: none;
    }

    &.homeButton a {
      margin-left: 0;
      padding: 5px 18px;
    }
    &:only-child a {
      background-color: tint-color($core_border, 5%);
    }

    &.t-focus {
      background-color: tint-color($core_border, 15%);
    }

    &.t-context {
      background-color: tint-color($core_border, 65%);
    }
  }
}

.lu .rankingbuttons > .#{$tdp-css-prefix}-button-group {
  padding: 0 0 0 2px;
  max-width: 64px;
  flex-wrap: wrap;

  > li > a {
    width: 30px;
    font-size: x-small;
    text-align: center;
    padding: 4px 0;

    &:hover, &:active, &:focus {
      background-color: tint-color($core_border, 5%);
    }
  }
}

;@import "sass-embedded-legacy-load-done:198";