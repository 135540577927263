.caleydo-importer {
  max-height: 70vh;
  overflow: auto;
}

.caleydo-importer-categorical {
  textarea.form-control {
    width: 100%;
    height: 15ex;
  }
}

;@import "sass-embedded-legacy-load-done:228";