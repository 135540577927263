/**
 * Common spacings and logos
 */
.navbar {
  padding-top: 0; // map-get($spacers, 1); // $spacers = bootstrap sass variables map
  padding-bottom: 0; // map-get($spacers, 1);

  .navbar-brand {
    padding-top: map-get($spacers, 1) * 0.5;
  }

  // caleydo logo for the app link
  .caleydo_app {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0;

    &::before {
      content: ' ';
      white-space: pre;
      display: block;
      background: $phovea-navbar-icon-url no-repeat center $phovea-navbar-icon-top;
      background-size: contain;
      height: 100%;
      width: $phovea-navbar-icon-width;
    }
  }

  .navbar-nav {
    gap: map-get($spacers, 1);
  }

  // hide caret icon of dropdowns in header
  .dropdown-toggle::after {
    content: none;
  }
}

.phovea-navbar {
  /**
   * Colors light theme
   */
  &.navbar-light {
    border-bottom: 1px solid shade-color(map-get($theme-colors, "light"), 10%);

    .navbar-brand {
      color: map-get($theme-colors, "dark");
    }

    .nav-link {
      color: map-get($theme-colors, "dark");

      &:hover,
      &:focus,
      &.hover { // add .hover class to set state programmatically from TDP tours
        background-color: shade-color(map-get($theme-colors, "light"), 10%);
        color: map-get($theme-colors, "dark");
      }
    }

    .active > .nav-link {
      background-color: shade-color(map-get($theme-colors, "light"), 10%);
      color: map-get($theme-colors, "dark");
    }
  }

  /**
   * Colors dark theme
   */
  &.navbar-dark {
    border-bottom-color: tint-color(map-get($theme-colors, "dark"), 10%);

    .navbar-brand {
      color: $white;
    }

    .nav-link {
      color: map-get($theme-colors, "light");

      &:active,
      &:hover,
      &:focus,
      &.hover { // add .hover class to set state programmatically from TDP tours
        background-color: tint-color(map-get($theme-colors, "dark"), 10%);
        color: map-get($theme-colors, "light");
      }
    }

    .active > .nav-link {
      background-color: map-get($theme-colors, "light");
      color: map-get($theme-colors, "dark");
    }
  }
}

// aboutDialog dialog that opens from a link in the header
#headerAboutDialog {
  .modal-title {
    text-transform: capitalize;
  }

  .caleydoInfo {
    margin-top: 15px;
    display: flex;
    align-items: stretch;
    gap: 15px;

    &::before {
      display: block;
      content: '';
      background: $phovea-about-dialog-icon-url no-repeat center top;
      background-size: contain;
      flex-basis: 40px;
      min-height: $phovea-about-dialog-icon-height;
    }

    p {
      flex: 1;
      margin-bottom: 0;
    }
  }
}

#headerReportBugDialog {
  *[data-header="bug"] textarea {
    width: 100%;
    height: 25vh;
    overflow: auto;
  }
}

;@import "sass-embedded-legacy-load-done:230";