.tdp-tooltip {
  position: absolute;
  display: none;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px;
  z-index: 1500;

  [x-arrow] {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
  }

  &::before {
    content: '';
    position: absolute;
    left: -6px;
    right: -6px;
    top: -6px;
    bottom: -6px;
    z-index: -1;
  }

  &[x-placement^=top] {
    margin-bottom: 5px;

    [x-arrow] {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^=bottom] {
    margin-top: 5px;

    [x-arrow] {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^=right] {
    margin-left: 5px;

    [x-arrow] {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent;
      border-top-color: transparent;
      border-bottom-color: transparent;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^=left] {
    margin-right: 5px;

    [x-arrow] {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
}

;@import "sass-embedded-legacy-load-done:201";