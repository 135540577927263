$cht-border-radius: 3px !default;
// styles for cohort
div.rectCohort {
  border: 1px solid $color-border-medium;
  border-radius: $cht-border-radius;
  background-color: $color-cohort-background;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  .rectCohort-label, .rectCohort-labelBar {
    padding: 0 0.25em;
    text-overflow: ellipsis;
    white-space: nowrap; // No line breaks
    overflow: hidden; // require for text-overflow to work
    text-align: center;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .rectCohort-sizeBar {
    background-color: $color-bar-background;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    border-bottom-right-radius: $cht-border-radius;
    border-bottom-left-radius: $cht-border-radius;

    .size-label{
      padding: 0 0.25em;
      position: absolute;
      top: 0;
      left: 0;
      color: $color-text;
      font-weight: bold;
      width: 100%;
      max-width: 100%;
    }

    .rectCohort-sizeBar-indicator {
      background-color: $color-bar;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .size-label-overlay-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      height: 100%;

      .size-label-overlay {
        padding: 0 0.25em;
        color: white;
        font-weight: bold;
      }
    }
  }

  .rectCohort-CB {
    text-align: center;
    vertical-align: middle;
    margin: 0 0 2px 0 !important;
  }

  .remove-cohort {
    position: absolute;
    top: 1px;
    right: 1px;
    color: $color-remove-icon;
    font-size: 0.7em;

    &:hover {
      color: $color-red !important;
    }
 
  }
}

;@import "sass-embedded-legacy-load-done:247";