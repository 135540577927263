.provenance-layout-vis {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;

  &.collapsed > div {
    display: none;
  }

  > div:first-child {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  > div:nth-of-type(2) {
    overflow-x: auto;
    max-height: 93vh;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  svg {
    g.edges path {
      fill: none;
      stroke: #949494;
    }
    g.storyhighlights path {
      opacity: 1;
      stroke: #010101;
      stroke-width: 2;
      //stroke-dasharray: 5 2;
      fill: none;
    }
  }

  .btn-filter {
    color: $core_colour_text;
    float: right;
    cursor: pointer;
    margin-right: 15px;

    &:hover {
      font-weight: bold;
    }
  }

  .btn-collapse {
    color: $core_colour_text;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  &.collapsed > .btn-collapse {
    color: $header-menu-color;
    top: 9px;
    right: 10px;
  }

  div.state {
    position: absolute;

    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;

    i.glyph {
      font-size: 50%;
      padding-right: 1px;
      margin-left: -4px;
      color: #949494;
    }

    &.story_member i.glyph {
      color: black;
    }

    .slabel {
      font-size: 10pt;
    }

    pointer-events: none;

    > div:hover {
      .slabel, .icon, .glyph {
        font-weight: bold; //: $hover-color;
      }
    }

    > div {
      pointer-events: all;
      position: relative;
    }

    .sthumbnail {
      position: absolute;
      right: 0;
      top: -1px;
      height: 100%;
      display: inline-block;
      border: 1px solid black;
      border-radius: 5px;

      background-position: left center;
      background-repeat: no-repeat;
      background-clip: border-box;
      background-size: contain;
      background-color: white;
    }

    .toolbar {
      display: none;
      position: absolute;
      top: 0;
      right: 0.1em;
      letter-spacing: 2px;

      i:hover {
        font-weight: bold;
      }
    }

    .icon {
      margin-right: 0.3em;
      color: #949494;
    }


    &.phovea-select-selected {
      font-weight: bold;
      //background-color: $select-color;

      .glyph, .icon, .slabel {
        color: $select-color;
      }
    }
    &.phovea-select-hovered {
      font-weight: bold;
      //background-color: $hover-color;

      .glyph, .icon {
        color: $hover-color;
      }

    }

    &.doi-xs {
      .sthumbnail, .slabel, .icon {
        display: none;
      }

      &.phovea-select-hovered {
        .glyph {
          transition: font-size 200ms;
          transition: margin-left 100ms;
          font-size: 100%;
          margin-left: -7px;
        }
      }
    }
    &.doi-sm {
      .sthumbnail, .slabel {
        display: none;
      }
      height: 20px;
      font-size: 100%;
      //padding-left: 13px;

    }
    &.doi {
      width: 100%;
      padding-right: 4px;
      //margin-left: 8px;
     .sthumbnail {
       display: none;
     }
      height: 30px;
      font-size: 100%;
      //padding-left: 10px;

      .glyph {
        padding-top: 12px;
      }

      > div {
        &:hover {
          .toolbar {
            display: block;
          }
        }
      }
    }

    &.doi-lg {
      //margin-left: 26px;
      margin-top: 3px;
      height: 44px;
      width: 100%;
      padding-right: 4px;
      .sthumbnail {
        width: 3em;
        height: 100%;
      }



      > div {
        height: 3em;
        border-radius: 5px 5px 0 0;
        border-top: 1px solid black;

        &:hover {
          .toolbar {
            display: block;
          }
        }
      }
    }




  }



  &.medium, &.small, &.xsmall {

    form.toolbar {
      display: none;
    }
  }

  &.small, &.xsmall {
    h2 {
      display: none;
    }
  }

  div.legend {
    position: absolute;

    right: 2px;
    bottom: 2px;

    label {
      text-align: left;
    }
  }
}

form.state_info {
  span.star {
    position: absolute;
    right: 0.2em;
    top: 0.2em;
    font-size: 200%;
  }

  input {
  }
  textarea {
    height: 5em;
  }

  div.img {
    width: 100%;
    text-align: center;
    img {
      min-width: 20em;
      width: auto;
      margin: auto 0;
      height: 12em;
      border: 1px solid black;
      background-image: url('../../assets/caleydo_c_anim_gray.svg');
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}

;@import "sass-embedded-legacy-load-done:211";